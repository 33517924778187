<template>
<section class="domain_content">
  <h1>
    Baixar a Última Versão do WhatsApp GB para Usuários de iPhone
  </h1>

  <p>
    Na verdade, os usuários não conseguem baixar <a href="/">WhatsApp GB</a> diretamente na Apple Store, pois é um aplicativo modificado baseado no WhatsApp oficial. No entanto, você pode baixar e instalar o WhatsApp GB IOS X Android no seu telefone.
  </p>
  <p>
    Mas é importante notar que esta versão apenas replica a interface do iOS; isso não significa que você pode executar o WhatsApp GB em um iPhone.
  </p>
  <p>
    Na verdade, os usuários de iPhone têm uma maneira de instalar uma alternativa ao WhatsApp GB. Mas isso requer o jailbreak do dispositivo. O jailbreak é um processo complexo e vem com riscos significativos, como vulnerabilidades de segurança e anulação da garantia. Portanto, não recomendamos esse método para instalar alternativas ao WhatsApp GB no iOS.
  </p>

  <h2>
    Baixar WhatsApp GB para Android
  </h2>

  <div class="old-version-column">
    <div class="version-item">
      <div class="old-version-row">
        <img src="@/assets/shiny-logo.webp" class="head-img" alt="logo" />
        <div class="version-detail">
          <h4>WhatsApp GB 18.20</h4>
          <p><strong>Tamanho:</strong> 77MB</p>
          <p><strong>Última Atualização:</strong> Há 2 Dias</p>
          <p><strong>Downloads:</strong> 1.000.000+</p>
          <p><strong>Sistema:</strong> Android</p>
        </div>
      </div>
      <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="baixar" class="download-img">
        <div class="download-name">
          Baixar
        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import '@/css/default.scss';
import {
  mapGetters
} from 'vuex';

export default {
  ...{
    "metaInfo": {
      "title": "WhatsApp GB para iPhone | Baixar Última Versão 2024",
      "meta": [{
        "name": "description",
        "content": "WhatsApp GB para iPhone 2024 atualizou uma nova versão. Baixe a versão mais recente para aproveitar mais recursos personalizados do que no WhatsApp normal."
      }, {
        "name": "title",
        "content": "WhatsApp GB para iPhone | Baixar Última Versão 2024"
      }, {
        "property": "og:title",
        "content": "WhatsApp GB para iPhone | Baixar Última Versão 2024"
      }, {
        "property": "og:description",
        "content": "WhatsApp GB para iPhone 2024 atualizou uma nova versão. Baixe a versão mais recente para aproveitar mais recursos personalizados do que no WhatsApp normal."
      }],
      "link": [{
        "rel": "canonical",
        "href": "https://waproapk.com/pt/gbwhatsapp-for-iphone/"
      }]
    }
  },
  data() {
    return {
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ])
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },

    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },

    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
